import React from "react";
import ReactPlayer from "react-player";
import "./slider.css";
import { Row } from "react-bootstrap";

const Slider = () => (
    <Row style={{placeContent:"center"}}>
        <ReactPlayer
        url="https://youtu.be/_mZEa8mqBNg"
        playing={true}
        loop={true}
        controls={false}
        muted={true}
        width={1366}
        height={768}
      />
    </Row>
);

export default Slider;
