import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link ,useMatch, useNavigate} from "react-router-dom";
import "./product-list.css";
const ProductList = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const match= useMatch(`e-store/${props.item.title.toLowerCase()}`)
  let components = match ? (
    <Container className="text-center">
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 close_">
              <button onClick={() => navigate(-1)} className="close_line">{t("common.back")}</button>
            </div>
      <Row>
        {props.item.data.map((product, index) => (
          <Col key={`${product.code}-${index}`} xs={6} sm={3} lg={3} md={3}>
            <Link
              to={`${product.code}`}
              className="col-md-3 col-lg-3 col-sm-4 col-xs-12 product_list_a"
            >
              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <img
                  alt=""
                  className="thumbnail"
                  src={`${props.item.root_src}/${decodeURI(
                    product.defaultAssetIndex
                      ? product.assets[product.defaultAssetIndex]
                      : product.assets[0]
                  )}`}
                />
              </div>
              <div className=" col-md-12 col-lg-12 col-sm-12 col-xs-12 product_list_title">
                {product.code}
                <br />
                {product.price}
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  ) : null;
  return <>{components}</>;
};

export default ProductList;
