import React from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import './shop.css';
import Divider from '../../../../components/common/divider';
import { useTranslation } from 'react-i18next'
import Instagram from '../instagram';
const Shop = () => {
    const { t } = useTranslation();

    return (<>
        <Row>
            <Col xl={12} md={12} sm={12} xs={12}>
                <Carousel>
                    <Carousel.Item >
                        <img className="img-fluid carousel_img col-md-5 col-6" src="/assets/home/banner/001.jpg" alt="" />
                        <img className="img-fluid carousel_img col-md-5 col-6" src="/assets/home/banner/002.jpg" alt="" />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img className="img-fluid carousel_img col-md-5 col-6" src="/assets/home/banner/003.jpg" alt="" />
                        <img className="img-fluid carousel_img col-md-5 col-6" src="/assets/home/banner/004.jpg" alt="" />
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
        <Row>
            <Col xl={12} md={12} sm={12} xs={12}>
                <div className="collection_text ">
                    <strong className="collection_strong">{t('home.followuson')}</strong>
                    <a href="https://www.instagram.com/gratude/" rel="noopener noreferrer" target="_blank"><img className="shop_img_vertical" src="assets/common/ins.jpg" alt="" />
                        <strong className="collection_strong">@Gratude</strong>
                    </a>
                </div>
            </Col>
        </Row>
        <Divider></Divider>
        <Instagram></Instagram>
        <Divider></Divider>
    </>)
}

export default Shop;