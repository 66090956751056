import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import { SideBySideMagnifier } from "react-image-magnifiers";
import { useTranslation } from "react-i18next";
import "./product-detail.css";
import { Link, NavLink ,useNavigate} from "react-router-dom";
const ProductDetail = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [url, setImage] = useState(`/assets/product/${props.category.title.toLowerCase()}/${props.item.assets[0]}`);

  return (
    <Container className="text-center">
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 close_">
        <button onClick={()=>navigate(-1)} className="close_line">{t("common.back")}</button>
      </div>
      <Row>
        <div className="col-md-5 col-lg-5 col-sm-5 col-xs-12">
          <div className="wrapper">
            <SideBySideMagnifier
              className="drift-demo-trigger"
              style={{ order: "0", userSelect: "contain" }}
              alwaysInPlace={false}
              overlayOpacity={0.6}
              switchSides={false}
              zoomPosition="left"
              inPlaceMinBreakpoint={641}
              fillAvailableSpace={false}
              fillAlignTop={false}
              zoomContainerBorder="1px solid #ccc"
              zoomContainerBoxShadow="0 4px 8px rgba(0,0,0,.5)"
              imageSrc={url}
            />
            <div></div>
          </div>
          <div className="col-md-8 col-lg-8 col-sm-8 col-xs-12 product_detail_other_img">
            <Row>
              {props.item.assets.map((asset, index) => (
                <div
                  key={`pimg-${index}`}
                  className="col-lg-4 col-md-4 col-sm-6 col-xs-6 thumb gallery text-left galeri_alan"
                >
                  <figure>
                    <Link to="#" className="handPointer">
                      <img loading="lazy"
                        onClick={() => setImage(`/assets/product/${props.category.title.toLowerCase()}/${asset}`)}
                        src={`/assets/product/${props.category.title.toLowerCase()}/${asset}`}
                        className="img-responsive thumbnail"
                        alt=""
                      />
                    </Link>
                  </figure>
                </div>
              ))}
            </Row>
          </div>
        </div>
        <div className="col-md-7 col-lg-7 col-sm-7 col-xs-7 product_detail_left">
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 product_detail_title"></div>
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 product_detail_code">
            {t("productdetail.code")} : <span>{props.item.code}</span>
          </div>
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 product_detail_compos">
            {t("productdetail.composition")} :{" "}
            <span>{props.item.composition}</span>
          </div>
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 product_detail_price">
            {props.item.price}
          </div>
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 product_detail_beden">
            <Row>
              {props.item.sizes.map((size, index) => (
                <div
                  key={`size-${index}`}
                  className="col-md-2 col-lg-2 col-sm-2 col-xs-2 beden_detail"
                >
                  {size}
                </div>
              ))}
            </Row>
          </div>
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 buy_alan">
            <NavLink to="/contact" className="buy_now">
              {t("productdetail.buy")}
            </NavLink>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default ProductDetail;
