import React, { useEffect, useState } from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import Axios from 'axios';
import './insta.css';

const Instagram = () => {
    const token="EAAISqIZBZBMHQBAL4C5h4v6nuaE6l3KKhS0HqM6V00m2OgxqpKSMDhQ7UsJvZClI7sEkHVb9vYarNQdR21OBC1ztJgfJiefzNAzDbim6l0ychaGCG86kv6ZBwlrO32YkBIumweCcOxgeGM8UpbZAN6wLLUPMnkAxNmRdOv7OpcDJyGkThYUbSA43QAJ1omwZB2aLxnYvqV3wZDZD"
    const [state, setState] = useState([]);
    const GetInstagramPictures = () => {
        Axios.get(`https://graph.facebook.com/v11.0/17841400715915971/media?limit=10&access_token=${token}`)
            .then(r => {
                let instas = [];
                let loader = [];
                r.data.data.forEach(element => {
                    loader.push(Axios.get(`https://graph.facebook.com/v11.0/${element.id}?fields=media_url,thumbnail_url,media_type&access_token=${token}`)
                        .then(({ data }) => {
                            instas.push((data.media_type === 'IMAGE' ? data.media_url : data.thumbnail_url));
                        }));
                });
                Promise.all(loader).finally(() => setState(instas));
            });
    }
    useEffect(() => {
        GetInstagramPictures();
    }, []);

    return (<>
        <Row>
            <Col xl={12} md={12} sm={12} xs={12}>
                <Carousel>
                    <Carousel.Item>
                        <img className="col-md-3 col-3 carousel_img img_insta" src={state[0]} alt="" />
                        <img className="col-md-3 col-3 carousel_img img_insta" src={state[1]} alt="" />
                        <img className="col-md-3 col-3 carousel_img img_insta" src={state[2]} alt="" />
                        <img className="col-md-3 col-3 carousel_img img_insta" src={state[3]} alt="" />
                        <img className="col-md-3 col-3 carousel_img img_insta" src={state[4]} alt="" />
                    </Carousel.Item>
                    <Carousel.Item >
                        <img className="col-md-3 col-3 img-fluid carousel_img img_insta" src={state[5]} alt="" />
                        <img className="col-md-3 col-3 img-fluid carousel_img img_insta" src={state[6]} alt="" />
                        <img className="col-md-3 col-3 img-fluid carousel_img img_insta" src={state[7]} alt="" />
                        <img className="col-md-3 col-3 img-fluid carousel_img img_insta" src={state[8]} alt="" />
                        <img className="col-md-3 col-3 img-fluid carousel_img img_insta" src={state[9]} alt="" />
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>

    </>)
}

export default Instagram;